import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(_theme => ({
  main: {
    marginTop: '64px',
    padding: '0px 100px',
    transition: '0.25s all ease',
    '@media (min-width: 1200px)': {
      padding: '0px 100px 70px'
    },
    '@media (max-width: 1200px)': {
      padding: '0px 48px',
      marginTop: '100px'
    },
    backgroundColor: '#EEEFF1',
    width: '100%'
  },
  mainMD: {
    padding: '24px',
    transition: 'all 0.25s ease-in-out',
    marginTop: '64px',
    marginLeft: 0,
    backgroundColor: '#EEEFF1',
    width: '100%'
  },
  title: {
    textAlign: 'center',
    width: '100%'
  },
  cardsBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardButton: {
    display: 'block',
    margin: '0 0 0 auto'
  },
  img: {
    marginTop: '40px'
  },
  formControl: {
    marginBottom: '40px'
  },
  formControlSmall: {
    marginBottom: '24px'
  },
  formLabel: {
    fontWeight: 'bold',
    marginBottom: '12px'
  },
  errorText: {
    color: '#B00020',
    marginTop: '16px'
  },
  membersFooter: {
    margin: '24px 0'
  }
}))
