import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardSlice, surveySlice, userSlice, teamSlice } from '../../reducers'
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  LinearProgress,
  Button,
  CircularProgress
} from '@material-ui/core'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { YouMenu, Back, TeamsMenu, ReportPDF } from '../'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { getStageByScore } from '../../helpers/utils'
import { useEvent } from '@emerald-works/react-event-bus-client'

const NavigationDrawer = ({
  activeDashboardLink,
  activeInnerLink,
  setActiveDashboardLink,
  setActiveInnerLink,
  activeSurveyStep,
  activeSurveyInnerStep,
  setActiveSurveyStep,
  setActiveSurveyInnerStep,
  stepsProgress,
  percentageCompleted,
  isLoading,
  isLGScreen,
  showNavBar,
  closeNavBar,
  hideNav,
  ovdHideNav,
  onMouseOver
}) => {
  const classes = useStyles()
  const pathname = window.location.pathname.split('/')[1]
  const surveyPage = pathname === 'review'
  const resultsPage = pathname === 'dashboard'
  const teamsPage = pathname === 'teams' || pathname === 'upgrade' || pathname === 'team-create' || pathname === 'team-dashboard'
  const userResults = useSelector(surveySlice.selectors.selectResults)
  const disableReview = userResults && userResults.score
  const menuLevel = useSelector(userSlice.selectors.selectMenuLevel)
  const activeMenuSection = useSelector(userSlice.selectors.selectActiveMenuSection)
  const userData = useSelector(userSlice.selectors.selectUserData)
  const teamData = useSelector(teamSlice.selectors.selectTeam)
  const userCSVUrl = useSelector(surveySlice.selectors.selectSeasonCSV)
  const isTeams = userData?.teams?.length || userData?.premium === 'team'
  const isAdmin = userData && (userData?.premium === 'team' || userData?.premium === 'consultant')
  const teamsInfo = isTeams
    ? {
        teamAdmin: isAdmin,
        teamResults: teamData && teamData.status === 'closed',
        hasTeam: !!userData?.teams?.length
      }
    : false
  const teamResults = teamsInfo.teamResults
  const isXLScreen = isLGScreen

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const readingLinks = useSelector(surveySlice.selectors.selectReadings)
  const { setPDFDownloading } = dashboardSlice.actions
  const chartData = useSelector(surveySlice.selectors.selectChartData)
  const [getSignedUrlForReport] = useEvent([surveySlice.eventBus.getSignedUrlForReport])
  const [getUserReportUrl] = useEvent([surveySlice.eventBus.getUserReportUrl])
  const [getSeasonUserCSV] = useEvent([surveySlice.eventBus.getSeasonUserCSV])
  const featureToggles = useSelector(userSlice.selectors.selectFeatureToggles)

  // for upload to s3
  const signedUrlForReport = useSelector(surveySlice.selectors.selectSignedUrlForReport)
  const reportImagesLoaded = useSelector(dashboardSlice.selectors.selectReportImagesLoaded)
  const isPDFDownloading = useSelector(dashboardSlice.selectors.selectPDFDownloading)
  // for download from s3
  const userReportUrl = useSelector(surveySlice.selectors.selectUserReportUrl)
  const userReportError = useSelector(surveySlice.selectors.selectUserReportError)

  const [isDownloading, setIsDownloading] = React.useState(false)
  const [isCSVDownloading, setIsCSVDownloading] = React.useState(false)
  const isDownloadEnabled = featureToggles['download-pdf-button'] && featureToggles['download-pdf-button'].active
  const checkEnter = (e) => e.key === 'Enter'

  const handleDashboarLink = link => {
    if (!resultsPage) {
      navigate('/dashboard')
    } else {
      setActiveDashboardLink(link)
      if (!isLGScreen) {
        closeNavBar()
      }
    }
  }

  const handleNavigate = (path) => {
    if (path === '/') {
      dispatch(userSlice.actions.setActiveMenuSection('home'))
      dispatch(userSlice.actions.setMenuLevel(0))
      navigate(path)
    }
    if (path === '/review') {
      dispatch(userSlice.actions.setActiveMenuSection('you'))
      dispatch(userSlice.actions.setMenuLevel(1))
      navigate(path)
    }
    if (path === '/dashboard') {
      dispatch(userSlice.actions.setMenuLevel(1))
      if (!resultsPage) {
        dispatch(userSlice.actions.setActiveMenuSection('you'))
        handleDashboarLink(path)
      }
    }
    if (path === '/upgrade' || path === '/team-create') {
      dispatch(userSlice.actions.setMenuLevel(0))
      if (!teamsPage) {
        dispatch(userSlice.actions.setActiveMenuSection('teams'))
        navigate(path)
      }
    }
    if (path === '/teams' || path === '/team-dashboard') {
      dispatch(userSlice.actions.setMenuLevel(1))
      if (!teamsPage) {
        dispatch(userSlice.actions.setActiveMenuSection('teams'))
        navigate(path)
      }
    }
  }

  React.useEffect(() => {
    if (!signedUrlForReport) {
      getSignedUrlForReport.trigger()
    }

    return () => {

    }
  }, [signedUrlForReport, getSignedUrlForReport])

  const handleDownloadClick = () => {
    if (userReportUrl) {
      return window.open(userReportUrl, '_blank')
    } else {
      dispatch(setPDFDownloading(true))
      setIsDownloading(true)
    }
  }

  const handleCSVDownloadClick = () => {
    if (userCSVUrl) {
      return window.open(userCSVUrl, '_blank')
    } else {
      getSeasonUserCSV.trigger()
      setIsCSVDownloading(true)
    }
  }

  const handleDownload = async () => {
    // if the user has a report ready, simply download it
    if (userReportUrl) {
      setIsDownloading(false)
      dispatch(setPDFDownloading(false))
      dispatch(dashboardSlice.actions.setReportImagesLoaded(false))
      return window.open(userReportUrl, '_blank')
    }
    // else, we need to get the presigned url for upload
    if (!signedUrlForReport) {
      getSignedUrlForReport.trigger()
    }
    const doc = (
      <ReportPDF
        scoresChartData={chartData.scoresChartData}
        componentsChartData={chartData.componentsChartData}
        profile={chartData.profile}
        behaviours={chartData.behaviours}
        stage={getStageByScore(Number.parseInt(chartData.results.score))}
        readingLinks={readingLinks}
      />
    )
    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()

    await fetch(signedUrlForReport, { body: blob, method: 'PUT' })
    saveAs(blob, 'LPB_REPORT.pdf')
    setIsDownloading(false)
    dispatch(setPDFDownloading(false))
    dispatch(dashboardSlice.actions.setReportImagesLoaded(false))
  }

  React.useEffect(() => {
    if (!userReportUrl && !userReportError) {
      getUserReportUrl.trigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReportUrl, userReportError])

  React.useEffect(() => {
    if (!reportImagesLoaded || !isDownloading || !isPDFDownloading) {
      return
    }
    setTimeout(() => {
      handleDownload()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportImagesLoaded, isDownloading, isPDFDownloading])

  React.useEffect(() => {
    if (userCSVUrl && isCSVDownloading) {
      window.open(userCSVUrl, '_blank')
      setIsCSVDownloading(false)
    }
  }, [userCSVUrl, isCSVDownloading])

  React.useEffect(() => {
    if (!userCSVUrl) {
      getSeasonUserCSV.trigger()
    }
  }, [userCSVUrl, getSeasonUserCSV])

  const drawerStyle = !surveyPage ? classes.drawer : classes.bigDrawer
  const paperStyle = !surveyPage ? classes.paper : classes.bigPaper
  const sectionProgress = {}
  const percentage = percentageCompleted > 100 ? 100 : percentageCompleted
  if (stepsProgress !== null && stepsProgress !== undefined) {
    Object.keys(stepsProgress).forEach(label => {
      const progress = `${stepsProgress[label][0]}/${stepsProgress[label][1]}`
      const completed = stepsProgress[label][0] >= stepsProgress[label][1]
      const error = stepsProgress[label][0] > 0 && stepsProgress[label][0] < stepsProgress[label][1]
      sectionProgress[label] = {
        progress,
        completed,
        error
      }
    })
  }
  const handleBack = () => {
    dispatch(userSlice.actions.setMenuLevel(0))
  }

  const handleTeamsPath = () => {
    if (!teamsInfo) {
      return '/upgrade'
    }

    const { teamAdmin, teamResults, hasTeam } = teamsInfo

    if (teamAdmin) {
      return !hasTeam
        ? '/team-create'
        : '/teams'
    }
    return teamResults ? '/team-dashboard' : '/'
  }

  return (
    <nav
      name='left navigation'
      aria-label='left navigation'
      className={classes.root}
      onMouseOver={onMouseOver}
    >
      {isXLScreen &&
        <div className={classes.toolbarXL}>
          <List role='group'>
            <ListItem role='list' className={`${classes.listItemXL} ${activeMenuSection === 'home' && classes.activeItemXL}`} onClick={() => handleNavigate('/')}>
              <HomeOutlinedIcon className={classes.infoIcon} />
              <ListItemText
                className={activeMenuSection === 'home' ? classes.activeItem : classes.item}
                primary='Home'
                disableTypography
                data-test='nav-home'
              />
            </ListItem>
            <ListItem role='list' className={`${classes.listItemXL} ${activeMenuSection === 'you' && classes.activeItemXL}`} onClick={() => disableReview ? handleNavigate('/dashboard') : handleNavigate('/review')}>
              <PersonOutlinedIcon className={classes.infoIcon} />
              <ListItemText
                role='listitem'
                className={activeMenuSection === 'you' ? classes.activeItem : classes.item}
                primary='You'
                disableTypography
                data-test='nav-you'
              />
            </ListItem>
            <ListItem
              role='list'
              className={`${classes.listItemXL} ${activeMenuSection === 'teams' && classes.activeItemXL}`}
              onClick={() => handleNavigate(handleTeamsPath())}
              disabled={!teamsInfo.hasTeam ? false : !teamsInfo.teamAdmin ? !teamsInfo.teamResults : false}
            >
              <PeopleAltOutlinedIcon className={classes.infoIcon} />
              <ListItemText
                role='listitem'
                className={activeMenuSection === 'teams' ? classes.activeItem : classes.item}
                primary='Teams'
                disableTypography
                data-test='nav-teams'
              />
            </ListItem>
          </List>
        </div>}
      <Drawer
        className={drawerStyle}
        classes={
          !isXLScreen
            ? { paper: ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${paperStyle} ${isXLScreen && classes.marginXL}` : classes.paperHide }
            : {
              // if the menu level is 0, classes.paperHide is used
              // if the menu level is 1, classes.paperHide is only used if ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav))
                paper: (menuLevel === 0 ? classes.paperHideHome : ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${paperStyle} ${classes.marginXL}` : classes.paperHide)
              }
        }
        open={isXLScreen ? menuLevel === 1 : showNavBar}
        variant={isLGScreen && showNavBar ? 'permanent' : 'temporary'}
        anchor='left'
        onClose={closeNavBar}
        id='left-nav-drawer'
      >

        <div className={`${classes.toolbar}`} />
        <Divider />
        {menuLevel === 1 && activeMenuSection === 'you' &&
          <List role='group'>
            {!isXLScreen &&
              (<><ListItem role='list' onClick={handleBack}><Back /></ListItem><Divider /></>)}
            <ListItem role='list'>
              <YouMenu
                page={pathname}
                activeSurveyInnerStep={activeSurveyInnerStep}
                activeSurveyStep={activeSurveyStep}
                sectionProgress={sectionProgress}
                activeDashboardLink={activeDashboardLink}
                setActiveDashboardLink={setActiveDashboardLink}
                setActiveSurveyInnerStep={setActiveSurveyInnerStep}
                setActiveSurveyStep={setActiveSurveyStep}
                closeNavBar={closeNavBar}
                isLGScreen={isLGScreen}
                setActiveInnerLink={setActiveInnerLink}
                activeInnerLink={activeInnerLink}
                resultsPage={resultsPage}
                percentage={percentage}
              />
            </ListItem>
          </List>}
        {menuLevel === 1 && activeMenuSection === 'teams' &&
          <List role='group'>
            {!isXLScreen &&
              (<><ListItem role='list' onClick={handleBack}><Back /></ListItem><Divider /></>)}
            <ListItem role='list'>
              <TeamsMenu
                activeDashboardLink={activeDashboardLink}
                setActiveDashboardLink={setActiveDashboardLink}
                closeNavBar={closeNavBar}
                isLGScreen={isLGScreen}
                setActiveInnerLink={setActiveInnerLink}
                activeInnerLink={activeInnerLink}
                resultsPage={teamsPage}
                userData={userData}
                teamResults={teamResults}
              />
            </ListItem>
          </List>}
        {!menuLevel && !isXLScreen &&
          <div className={classes.drawerContainer}>
            {
              isLoading
                ? (
                  <Skeleton style={{ marginLeft: '16px' }} variant='text' animation='wave' height='45px' width='75%' />
                  )
                : (
                  <List>
                    <ListItem onClick={() => handleNavigate('/')} onKeyDown={(e) => checkEnter(e) ? handleNavigate('/') : null} tabindex={0} className={activeMenuSection === 'home' ? classes.menuItemActive : classes.menuItem}>
                      <HomeOutlinedIcon className={classes.infoIcon} />
                      <ListItemText
                        className={activeMenuSection === 'home' ? classes.activeItem : classes.item}
                        primary='Home'
                        disableTypography
                        data-test='nav-home'
                      />
                      <ArrowForwardIos className={classes.arrowIcon} />
                    </ListItem>
                  </List>
                  )
            }
            <Divider />
            {
              isLoading
                ? (
                  <Skeleton style={{ marginLeft: '16px' }} variant='text' animation='wave' height='45px' width='75%' />
                  )
                : (
                  <List role='group'>
                    <ListItem role='list' onClick={() => disableReview ? handleNavigate('/dashboard') : handleNavigate('/review')} onKeyDown={(e) => checkEnter(e) ? (disableReview ? handleNavigate('/dashboard') : handleNavigate('/review')) : null} tabindex={0} className={activeMenuSection === 'you' ? `${classes.menuItemActive} Teams_Left_Nav` : `${classes.menuItem} Teams_Left_Nav`}>
                      <PersonOutlinedIcon className={classes.infoIcon} />
                      <ListItemText
                        role='listitem'
                        className={activeMenuSection === 'you' ? classes.activeItem : classes.item}
                        primary='You'
                        disableTypography
                        data-test='nav-you'
                      />
                      <ArrowForwardIos className={classes.arrowIcon} />

                    </ListItem>
                  </List>
                  )
            }
            <Divider />
            {
              isLoading
                ? (
                  <Skeleton style={{ marginLeft: '16px' }} variant='text' animation='wave' height='45px' width='75%' />
                  )
                : (
                  <List role='group'>
                    <ListItem disabled={!teamsInfo.hasTeam ? false : !teamsInfo.teamAdmin ? !teamsInfo.teamResults : false} role='list' onClick={() => handleNavigate(handleTeamsPath())} onKeyDown={(e) => checkEnter(e) ? handleNavigate('/teams') : null} tabindex={0} className={activeMenuSection === 'teams' ? classes.menuItemActive : classes.menuItem}>
                      <PeopleAltOutlinedIcon className={classes.infoIcon} />
                      <ListItemText
                        role='listitem'
                        className={activeMenuSection === 'teams' ? classes.activeItem : classes.item}
                        primary='Teams'
                        disableTypography
                        data-test='nav-teams'
                      />
                      <ArrowForwardIos className={classes.arrowIcon} />

                    </ListItem>
                  </List>
                  )
            }

            {!menuLevel &&
              <>
                <div className={classes.boxGrow} />
                <div className={classes.ewLogo}>
                  <img
                    src='img/ew-logo-greyscale.png'
                    alt='Emerald Works Logo'
                  />
                </div>
              </>}

          </div>}
        {surveyPage && menuLevel === 1 && (
          <div className={`${classes.percentageBlock} ${activeSurveyStep === 0 || activeSurveyStep === 5 ? '' : classes.percentageBlockAdjustedMargin}`}>
            <div className={classes.percentage}>
              <LinearProgress aria-label='review progress bar' classes={{ colorPrimary: classes.linearDeterminateBackground, barColorPrimary: classes.linearDeterminateBar }} variant='determinate' value={percentage} />
            </div>
            <Typography variant='body2'>{percentage}% Complete</Typography>

            <img
              src='img/ew-logo-greyscale.png'
              alt='Emerald Works Logo'
              className={classes.ewLogoOnSurvey}
            />

          </div>
        )}
        {!surveyPage && menuLevel === 1 && (
          <>
            <div className={classes.boxGrow} />
            <div className={classes.ewLogo}>
              {resultsPage && showNavBar && (
                <div className={classes.downloadButtonContainer}>
                  {isLoading
                    ? (
                      <Skeleton>
                        <Button variant='contained'>Download responses</Button>
                      </Skeleton>
                      )
                    : (
                      <>

                        <Button
                          className={classes.downloadButton}
                          color='secondary'
                          variant='contained'
                          onClick={handleCSVDownloadClick}
                          data-test='btn-download-responses'
                          endIcon={
                            isCSVDownloading
                              ? <CircularProgress size={16} className={classes.downloadProgress} />
                              : null
                          }
                        >
                          Download responses
                        </Button>
                      </>)}
                </div>
              )}
              {resultsPage && showNavBar && isDownloadEnabled && (
                <div className={classes.downloadButtonContainer}>
                  {isLoading
                    ? (
                      <Skeleton>
                        <Button variant='contained'>Download results</Button>
                      </Skeleton>
                      )
                    : (
                      <>

                        <Button
                          className={classes.downloadButton}
                          color='secondary'
                          variant='contained'
                          onClick={handleDownloadClick}
                          data-test='btn-download-results'
                          endIcon={
                            isDownloading
                              ? <CircularProgress size={16} className={classes.downloadProgress} />
                              : null
                          }
                        >
                          Download Results
                        </Button>
                      </>)}
                </div>
              )}
              <img
                src='img/ew-logo-greyscale.png'
                alt='Emerald Works Logo'
              />
            </div>
          </>
        )}
      </Drawer>
    </nav>
  )
}

export default NavigationDrawer
