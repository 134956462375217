import React from 'react'
import { useSelector } from 'react-redux'
import { surveySlice } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { AppToolbar, MailtoLink, ContactForm } from '../../components'
import { Grid, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'

const ContactPage = () => {
  const classes = useStyles()
  const twitterLink = 'https://twitter.com/MTInsights'
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = React.useState(false)

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)

  const handleTwitter = () => {
    window.open(twitterLink)
  }

  const handleHome = () => {
    navigate('/')
  }

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />
      <Grid container>
        <div className={isLGScreen ? classes.main : classes.mainMD} id='main'>
          <Grid container spacing={6}>
            <Grid item lg={7} md={8}>
              {emailSent
                ? (
                  <span>
                    <Typography className={classes.title} variant='h1' data-test='contactHeader'>
                      Thank you!
                    </Typography>
                    <Typography className={classes.description}>
                      We have received your message and someone from our team will be in contact within 48 hours.
                    </Typography>
                    <Button onClick={handleHome} className={classes.buttons} variant='contained' color='secondary'>Homepage</Button>
                  </span>
                  )
                : (
                  <span>
                    <Typography className={classes.title} variant='h1' data-test='contactHeader'>
                      Get in touch
                    </Typography>
                    <Typography className={classes.description}>
                      Got a question about the Learning Performance Benchmark? Want to tell us about your experience? Fill out our quick form and we’ll aim to get back to you in the next 3 working days, if not sooner.
                    </Typography>
                    <ContactForm setEmailSent={setEmailSent} />
                  </span>
                  )}
            </Grid>
            <Grid style={{ display: !isLGScreen ? 'none' : '' }} lg={1} />
            <Grid item xs={4}>
              <div className={classes.rightColumn}>
                <img src='/img/contact.png' alt='' className={classes.image} />
                <div className={classes.socialBox}>
                  <MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com'>
                    lpbenchmark@mindtools.com
                  </MailtoLink>
                  <Typography className={classes.socialText} variant='h6'>
                    Connect with us:
                    <span
                      className={classes.socialIcon}
                      data-test='lnk-twitter'
                      onClick={handleTwitter}
                      onKeyDown={(e) => e.key === 'Enter' ? handleTwitter() : null}
                      tabindex={0}
                    >
                      <img src='/img/social.png' alt='LPB Twitter' />
                    </span>
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  )
}

export default ContactPage
