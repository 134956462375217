import React, { useEffect } from 'react'
import { AppToolbar, CookieyesPolicyTable } from '../../components'
import { Button, Grid, Typography } from '@material-ui/core'
import { useStyles } from './style'

const CookiesPolicy = () => {
  const classes = useStyles()

  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={12}>
          <Typography className={classes.headTitle} variant='h1'>
            Cookies Policy
          </Typography>
          <Typography className={classes.headContent} variant='body1'>
            <strong>IMPORTANT – PLEASE READ</strong>
          </Typography>

          <Grid className={classes.manageCookies} item xs={12}>
            <Typography className={classes.headContent} variant='body1'>
              <strong>Manage your cookie preferences</strong>
            </Typography>
            <Typography className={classes.headContent} variant='body1'>
              You can change your cookie preferences at any time by clicking the button below. This will let you revisit the cookie consent banner and change your preferences or withdraw your consent immediately.
            </Typography>
            <Button variant='contained' className={`${classes.cookiesButton} cky-banner-element`} disableRipple>
              Manage cookie preferences
            </Button>
          </Grid>
          <br />
          <Grid item xs={12}>

            <Typography className={classes.headContent} variant='body1'>
              <strong>About our cookies policy</strong>
            </Typography>
            <Typography className={classes.headContent} variant='body1'>
              This cookies policy is issued on behalf of Emerald Works Limited (
              <strong>Emerald</strong>). Our cookies policy explains how cookies
              and other tracking devices are used on our website at{' '}
              <a
                className={classes.linkExternal}
                href='https://lpbenchmark.mindtools.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                www.lpbenchmark.mindtools.com
              </a>
              .
            </Typography>
          </Grid>

        </Grid>

        <Grid className={classes.boxIntro} item xs={12}>
          <Typography className={classes.subTitle} variant='h3'>
            About cookies and how we use them
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Cookies are small data files which are placed on your computer or
            other device when you visit our website. The cookies are then used
            to gather information about your use of a site or in some cases, to
            allow you to be recognised as an existing user when returning to our
            website.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Cookies are commonly used to help websites work more efficiently and
            to provide information to website owners. They help our website to
            work in the way that you would expect so that we can provide you
            with a good experience when you use our website.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Note that where we provide links to other websites, such as the link
            we provide to our Facebook, Twitter, Instagram and LinkedIn social
            media page, those third-party websites may also use cookies and you
            should familiarise yourself with the cookies information that they
            provide on their own websites. We are not responsible for any
            cookies used on third party websites to which we may provide
            links.More specifically, we use the types of cookies below at{' '}
            <a
              className={classes.linkExternal}
              href='https://www.lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lpbenchmark.mindtools.com
            </a>
            . The table below also provides information about what the cookies
            are used for.
          </Typography>
        </Grid>

        <Grid className={classes.boxTable} item spacing={1} xs={12}>
          <CookieyesPolicyTable />
        </Grid>
        <Grid className={classes.box} item xs={12}>
          <Typography className={classes.subTitle} variant='h3'>
            Cookie settings and more information
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Broadly, you can block cookies by activating the setting on your
            browser which allows you to refuse the setting of cookies. Be aware
            however that if you use browser settings to block cookies, you may
            not be able to access all or some parts of the site and our site may
            not work as intended. This is especially the case for our website
            because we use a minimal number of cookies and these are aimed at
            making our website function properly in the way that you would
            expect. You can find out more about how to manage and delete
            cookies by visiting{' '}
            <a
              className={classes.linkExternal}
              href='https://www.allaboutcookies.org/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.allaboutcookies.org
            </a>.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={12}>
          <Typography className={classes.subTitle} variant='h3'>
            Consent to use of cookies from our website
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            English law requires all websites to request permission when using
            cookie technology. If your browser is set to accept cookies and
            other similar technologies and if you continue to use our website,
            then we will understand this to mean that you consent to the use of
            cookies or other similar technologies. However, if you wish to
            remove or stop use of cookies from our site then you should either
            stop using the site or adjust the settings of your browser as
            explained above, to reject or disable cookies.
          </Typography>
        </Grid>
      </Grid>

    </div>
  )
}

export default CookiesPolicy
