import React from 'react'
import { Typography } from '@material-ui/core'
import { MailtoLink } from '../.'
import { useStyles } from './style'
const HomePageSpinner = () => {
  const classes = useStyles()
  return (
    <div className={classes.rootLoading}>
      <div className='loadingio-spinner-spin-k1belx2d7a'>
        <div className='ldio-78fhm4xqnpp'>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </div>
      </div>
      <Typography variant='h1' className={classes.loadText}>Sit tight! We’re just fetching your information.</Typography>
      <Typography variant='body1' className={classes.loadFooter}>
        © 2022 Emerald Works Limited. All rights reserved. Having trouble? Email
        us at{' '}
        <MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com'>
          lpbenchmark@mindtools.com
        </MailtoLink>
      </Typography>
    </div>
  )
}
export default HomePageSpinner
