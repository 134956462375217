import React from 'react'
import { useStyles } from './style'
import {
  AppToolbar,
  PageAlert
} from '../../components'
import {
  ResultsForm,
  UserForm
} from './components'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { surveySlice, userSlice } from '../../reducers'
import { useNavigate } from 'react-router-dom'
import { useSession } from '@emerald-works/react-auth'

const DevToolsPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { user } = useSession()
  const featureToggles = useSelector(userSlice.selectors.selectFeatureToggles)
  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)

  const userFormSuccess = useSelector(userSlice.selectors.selectUserUpdateSuccess)
  const resultsFormSuccess = useSelector(surveySlice.selectors.selectResultsUpdateSuccess)

  const [showUserAlert, setShowUserAlert] = React.useState(false)
  const [showResultsAlert, setShowResultsAlert] = React.useState(false)

  React.useEffect(() => {
    if (userFormSuccess) {
      setShowUserAlert(true)
      setTimeout(() => {
        setShowUserAlert(false)
      }, 6000)
    }
    if (resultsFormSuccess) {
      setShowResultsAlert(true)
      setTimeout(() => {
        setShowResultsAlert(false)
      }, 6000)
    }
  }, [userFormSuccess, resultsFormSuccess])

  const getDevTools = () => {
    if (!featureToggles) return
    if (!featureToggles['lpb-dev-tools']?.active) return false
    if (featureToggles['lpb-dev-tools'].active) {
      const { userIds } = featureToggles['lpb-dev-tools'].strategies[0].parameters
      const list = userIds.split(',')
      if (list.includes(user.attributes.email)) return true
    }
    return false
  }

  const isDevToolsEnabled = getDevTools()

  React.useEffect(() => {
    if (!isDevToolsEnabled) {
      navigate('/')
    }
  }, [isDevToolsEnabled, navigate])

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />
      <Grid container>

        <div className={isLGScreen ? classes.main : classes.mainMD} id='main'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant='h1'
                data-test='devToolsHeader'
              >
                Dev tools
              </Typography>
              <Typography className={classes.description}>
                Use these tools to change the state of your account.
                Please navigate here from the homepage first to load your user data correctly.
              </Typography>
            </Grid>
            <Grid item xs={6}>

              <Typography
                variant='h2'
                data-test='devToolsHeader'
              >
                User table
              </Typography>
              <UserForm />
            </Grid>
            <Grid item xs={6}>

              <Typography
                variant='h2'
                data-test='devToolsHeader'
              >
                Results table
              </Typography>
              <ResultsForm />
            </Grid>

          </Grid>
        </div>
      </Grid>
      {showUserAlert && (<PageAlert title='User Updated' text='Successfully updated user. You may need to refresh and clear your cache.' />)}
      {showResultsAlert && (<PageAlert title='Results Updated' text='Successfully updated results. You will need to refresh and clear your cache.' />)}
    </div>
  )
}

export default DevToolsPage
