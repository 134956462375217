import * as Survey from 'survey-react'
import { Dropdown } from '../../../../components'

const CustomTooltipWidget = {
  name: 'customtooltip',
  title: 'Custom Tooltip',
  iconName: '',
  htmlTemplate: '<div></div>',
  widgetIsLoaded: function () {
    return true
  },
  isFit: function (question) {
    return question.getType() === 'customtooltip'
  },
  init: function (question, element) {},
  activatedByChanged: function (activatedBy) {
    // get properties from rating widget
    Survey.JsonObject.metaData.addClass('customtooltip', [], null, 'empty')
    Survey.JsonObject.metaData.addProperties('customtooltip', [
      { name: 'text', default: 'Tooltip text' }
    ])
  },
  isDefaultRender: false,
  render: function (question) {
    return (
      <Dropdown
        text={question.text}
      />
    )
  },
  afterRender: function (question, element) {}
}
export default CustomTooltipWidget
