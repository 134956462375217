import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { AppToolbar, LPBLink } from '../../components'
import { useStyles } from './style'

const PrivacyPolicy = () => {
  const classes = useStyles()

  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={8}>
          <Typography className={classes.headTitle} variant='h1'>
            Privacy Policy
          </Typography>
          <Typography className={classes.headContent} variant='body1'>
            We, Emerald Works Limited (<strong>“Emerald”</strong>) (also trading
            as “Mind Tools”), are committed to protecting your personal data.
            This privacy policy explains how we, Emerald, handle the personal
            data that we hold, including the personal data we collect when you
            use this website or otherwise when we collect personal data from you
            such as if you call us or write to us.
          </Typography>
        </Grid>
        <Grid className={classes.boxIntro} item xs={8}>
          <Typography className={classes.content} variant='body1'>
            This policy also explains your privacy rights and how the law
            protects you, including how we comply with the General Data
            Protection Regulation (“GDPR”) and other related legislation that
            relates to the use of personal data.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Additional information may be provided on particular pages of this
            website, for example, on any specific pages where we collect
            personal data, and you should also refer to those. We also operate
            cookies on our website and information about that is also provided
            separately in our Cookies Policy.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            It is important that you read this privacy policy together with any
            other statements or fair processing notices we may provide on
            specific occasions when we collect or process personal data, so that
            you are fully aware of how and why we are using your data.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Data controller
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Emerald Works Limited is the data controller and is responsible for
            the use of your personal data. Our full details are set out below.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Contacting us
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you have any questions about this privacy policy, including any
            requests to exercise your data privacy rights, please contact:
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Address: Emerald Works Limited, Level 1, 50 Frederick Street,
            Edinburgh, Scotland EH2 1EX
          </Typography>
          <Typography variant='body1'>
            Email:{' '}
            <a
              className={classes.linkExternal}
              href='mailto:beinspired@mindtools.com?subject=Privacy%20Policy%20contact'
            >beinspired@mindtools.com
            </a>
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Tel: +44 2077 887 978
          </Typography>
          <Typography variant='body1'>
            You have the right to make a complaint to the Information
            Commissioner’s Office (ICO), which is the UK supervisory authority
            for data protection issues (
            <a
              className={classes.linkExternal}
              href='https://ico.org.uk/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.ico.org.uk
            </a>
            ). We would, however, like the opportunity to assist you with any
            concerns, so please contact us in the first instance using the
            details above.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Your obligations
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you provide us with personal data about any other person, for
            example any other individuals within your organization, then you
            must ensure that you have their consent to pass those details to us
            and for us to use those details in accordance with this privacy
            policy. You should also make sure that you bring this privacy policy
            to their attention.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Third party links
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            This website may include links to third party websites, such as our
            Twitter, Facebook and LinkedIn social media pages. Clicking on those
            links may allow third parties to collect or share data about you. We
            do not control these third party websites and are not responsible
            for their privacy policies. You should read any policies and other
            statements on such websites carefully.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Data we collect about you
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Personal data means any information about an individual from which
            that person can be identified. It does not include data that has
            been anonymized, such that a person’s identity is removed.
          </Typography>
          <Typography className={classes.highlight} variant='body1'>
            The following information explains the types of personal data that
            we collect, use, store and transfer. We will only use your personal
            data as the law allows us to. The categories of personal data that
            we use include:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              Identity and contact data such as names, titles and other
              identifiers together with address, email address and telephone
              numbers.
            </li>
            <li className={classes.paragraph}>
              Technical data including internet protocol (IP) address, your
              login data, browser type and version, browser plug-in types and
              versions, access times, operating system and platform and other
              technology on the devices you use to access this website. This may
              also include information about how you use our website.
            </li>
            <li className={classes.paragraph}>
              Profile data, such as username and password details.
            </li>
            <li className={classes.paragraph}>
              Marketing and communications data, which includes preferences in
              receiving marketing from us and your communication preferences.
            </li>
            <li className={classes.paragraph}>
              Career data, including your CV, career history information and
              information about you, including references.
            </li>
            <li className={classes.paragraph}>
              Financial data (if you are a consumer using the website{' '}
              <a
                className={classes.linkExternal}
                href='https://www.mindtools.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                www.mindtools.com
              </a>
              ) such as bank account or card payment details when you place an
              order by signing up to the Mind Tools Club.
            </li>
          </ul>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            How we will use your data
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The information below also explains the purposes for which we use
            different categories of personal data and the legal basis or bases
            that we believe applies to those uses, where you use the website{' '}
            <a
              className={classes.linkExternal}
              href='https://emeraldworks.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.emeraldworks.com
            </a>{' '}
            or{' '}
            <a
              className={classes.linkExternal}
              href='https://www.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.mindtools.com
            </a>
            . We may process your personal data for more than one lawful basis
            depending on the purpose for which we are using your data.
          </Typography>
          <Typography className={classes.highlight} variant='body1'>
            We may process your personal data for our legitimate business
            purposes (or those of our group companies), such as:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              to deliver our products and services;
            </li>
            <li className={classes.paragraph}>
              to administer this website, including troubleshooting, data
              analysis, testing, system maintenance and support, so that we can
              provide and improve the products and services we offer and ensure
              our website operates properly, and for network security;
            </li>
            <li className={classes.paragraph}>
              to provide you with marketing communications such as our
              newsletter if you have shown an interest in receiving information
              about our products and services;
            </li>
            <li className={classes.paragraph}>
              to assess and, where appropriate, deal with your application, if
              you express an interest in working for us;
            </li>
            <li className={classes.paragraph}>
              to ensure our third party providers can perform their obligations
              to us; and
            </li>
            <li className={classes.paragraph}>
              to gather your feedback on our products and services.
            </li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            If you are a consumer and use the website,{' '}
            <a
              className={classes.linkExternal}
              href='https://www.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.mindtools.com
            </a>
            , we may process your financial data to administer your subscription
            when you place an order and sign up to the Mind Tools Club, so that
            we can provide our services to you. This is necessary for the
            performance of the contract that will be in place between us.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            In addition, we may disclose information about you to the extent
            that we are required to do so by law or for the purpose of legal
            proceedings or prospective legal proceedings, in order to establish,
            exercise or defend our legal rights (including providing information
            to others for the purposes of fraud prevention) and where we may
            otherwise do so in accordance with applicable data protection
            legislation.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            How is your personal data collected?
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Most of the personal data that we hold is collected either directly
            through you (or your colleagues), or when you otherwise get in
            contact with us, for example, by email, over the phone, or through
            the use of our website. For example, you might provide certain
            identity information by completing one of our online forms such as
            on our{' '}
            <LPBLink className={classes.linkLPB} to='/contact' target='_blank' rel='noopener noreferrer'>
              'Contact us'
            </LPBLink>
            {' '}page, if you make an enquiry with us, or if you subscribe to our newsletter.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Some of the technical data that we collect may be collected
            automatically as you interact with our website, for example via the
            use of cookies and similar technologies.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            If you fail to provide personal data
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Where we need to collect personal data by law, under the terms of a
            contract we have with the organization you represent or for another
            lawful reason and you fail to provide that data, we may not be able
            to fully perform our obligations in accordance with our contract,
            provide you with the information you require or otherwise properly
            deal with your request.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Marketing and promotions
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            From time to time we may use your data including identity and
            contact data, technical data and marketing and communications data,
            to contact you with information about our products and services. You
            will receive marketing communications from us if you have agreed to
            be included on our marketing database.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You can ask us to stop sending you marketing messages at any time by
            contacting us at any time by calling us or writing to us by post or
            email, using the contact details provided above.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Change of purpose
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If we need to use your personal data for an unrelated
            purpose, we will explain the legal basis that allows us to do so.
            Please also note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you would like further information about how we use personal data
            and the purpose that we might use it for, please contact us using
            the contact details set out above.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Disclosures of your personal data
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We may transfer your personal data to affiliated companies within
            the Emerald Group. More information about our group companies can be
            found on the Emerald Group website here:{' '}
            <a
              className={classes.linkExternal}
              href='http://emeraldgroup.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.emeraldgroup.com
            </a>.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Your personal data may sometimes be electronically transferred
            outside the European Economic Union (EEA) (or, in the event the
            United Kingdom (UK) ceases to be a member of the EEA, the UK) to
            Emerald’s (and those of our group companies) international offices,
            for the purposes of providing our products and services and
            facilitating Emerald Group’s company central management and
            administration.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Whenever we transfer your personal data outside the EEA, we ensure a
            similar degree of protection is afforded to it by ensuring that at
            least one of the safeguards permitted by law is in place.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Data security
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorized way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know it. They will
            only process your personal data on our instructions, and they are
            subject to a duty of confidentiality.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Data retention
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will only retain your personal data for as long as necessary to
            fulfill the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting or reporting requirements.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            In some circumstances, we may anonymize personal data (so that it
            can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely
            without further notice to you.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Your legal rights
          </Typography>
          <Typography className={classes.highlight} variant='body1'>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. Those rights are listed
            below. Please contact us if you would like to exercise any of them.
            You have the right to:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              Request access to your personal data (a 'subject access request').
              This means you can receive a copy of the personal data we hold
              about you and to check that we are lawfully processing it.
            </li>
            <li className={classes.paragraph}>
              Request correction of your personal data. This enables you to have
              any incomplete or inaccurate data we hold about you corrected,
              though we may need to verify the accuracy of the new data you
              provide to us.
            </li>
            <li className={classes.paragraph}>
              Request erasure of your personal data. You can ask us to remove
              personal data where there is no good reason for us continuing to
              process it. You also have this right where you have successfully
              exercised your right to object to processing, where we may have
              processed your data unlawfully or where we are required to erase
              your personal data to comply with law. We may not always be able
              to comply with your request where there are particular legal
              reasons.
            </li>
            <li className={classes.paragraph}>
              Object to processing of your personal data where we rely on a
              legitimate interest basis and if the processing impacts on your
              fundamental rights and freedoms. You can also object to direct
              marketing. In some cases, we may demonstrate that we have grounds
              to process your data, which override your rights and freedoms.
            </li>
            <li className={classes.paragraph}>
              Request restriction of processing. You can ask us to suspend the
              processing of your personal data: if you want us to establish the
              data’s accuracy; where our data use is unlawful but you do not
              want us to erase it; where you need us to hold the data even if we
              no longer need it, to establish, exercise or defend legal claims;
              or you have objected to use of data but we need to verify whether
              we have overriding legitimate grounds to use it
            </li>
            <li className={classes.paragraph}>
              Request a data transfer. We will provide to you, or your chosen
              third party, your personal data in a structured, commonly used,
              machine-readable format. This only applies to automated
              information that you provided consent for us to use or is
              necessary to perform a contract with you.
            </li>
            <li className={classes.paragraph}>
              Withdraw consent at any time where we rely on consent to process
              your personal data. This will not affect the lawfulness of
              processing carried out before you withdraw your consent.
            </li>
          </ul>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Exercising your rights
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You will not have to pay a fee to access your personal data (or to
            exercise any other rights). However, we may charge a reasonable fee
            if your request is clearly unfounded, repetitive or excessive.
            Alternatively, we may refuse to comply with your request in these
            circumstances.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure personal data is not disclosed to a person who has
            no right to receive it. We may also contact you to ask you to
            clarify your request to speed up our response.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made several requests, in which
            case we will keep you updated.
          </Typography>
          <Typography className={classes.italic} variant='body1'>
            As Emerald is no longer resident within the EU, the GDPR requires us
            to appoint a GDPR representative under Article 27 of the GDPR, as a
            point of contact for you if you are an EU citizen. We have formally
            appointed GDPR-Rep.eu. If you are an EU citizen and you would like
            to make a data subject request, please visit{' '}
            <a
              className={classes.linkExternal}
              href='https://gdpr-rep.eu/q/12993813'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://gdpr-rep.eu/q/12993813.
            </a>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.boxBottom} variant='body1' />
          <LPBLink className={classes.linkLPBCookies} to='/cookies-policy' target='_blank' rel='noopener noreferrer'>
            Click here for our Cookies Policy
          </LPBLink>
        </Grid>
      </Grid>
    </div>
  )
}

export default PrivacyPolicy
